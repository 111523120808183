import React from 'react'
import Layout, { LAYOUT_TYPES } from '../modules/Layout'
import { graphql } from 'gatsby'
import Main from '../modules/Main'
import { LANGUAGE, TEMPLATE_TYPES } from '../common/constants'
import useSeo from '../hooks/useSeo'

const DATA_MAP = {
  title: ['name'],
  description: ['description', 'description'],
  image: ({ image }) => {
    return image?.fluid?.src || image?.gatsbyImageData?.images?.fallback?.src
  },
  altText: ['description', 'description'],
}

const getLayout = (data, helmetProps) => {
  const { slug, content, name, imageDisplayOptions, image } = data

  let imageLayout = {}

  if (!imageDisplayOptions || imageDisplayOptions === 'Full width') {
    imageLayout = {
      __typename: LAYOUT_TYPES.IMAGE,
      image: image,
    }
  }

  return [
    {
      __typename: LAYOUT_TYPES.MASTHEAD,
      headline: name,
      meta: LANGUAGE.responsible_travel_masthead_meta,
      to: `/${TEMPLATE_TYPES.RESPONSIBLE_TRAVEL}`,
    },
    { ...imageLayout },
    {
      __typename: LAYOUT_TYPES.BLOG_CONTENT,
      image: imageDisplayOptions && imageDisplayOptions === 'In content' ? image : null,
      content: content,
      shareProps: { ...helmetProps },
    },
    {
      __typename: LAYOUT_TYPES.SECTION,
      currentSlug: slug,
      description: LANGUAGE.responsible_travel_section_desc,
      parent: LANGUAGE.responsible_travel_parent,
    },
    {
      __typename: LAYOUT_TYPES.HELMET,
      ...helmetProps,
    },
  ]
}

const ResponsibleTravel = (props) => {
  const {
    data: { contentfulCharitablePartners },
  } = props

  const url = props.location.href

  const seoProps = useSeo(contentfulCharitablePartners, DATA_MAP, TEMPLATE_TYPES.RESPONSIBLE_TRAVEL)
  const layout = getLayout(contentfulCharitablePartners, seoProps)

  return (
    <Main {...props} path={url}>
      <Layout layout={layout} path={url} />
    </Main>
  )
}

export default ResponsibleTravel

export const responsibleTravelQuery = graphql`
  query ResponsibleTravelBySlug($slug: String!) {
    contentfulCharitablePartners(slug: { eq: $slug }) {
      __typename
      slug
      content {
        raw
        references {
          __typename
          ... on ContentfulDestinations {
            contentful_id
            slug
          }
          ###
          # Commented out as we don't have these content types yet
          ###
          # ... on ContentfulLinks {
          #   ...LinkFields
          # }
          # ... on ContentfulAsset {
          #   contentful_id
          #   title
          #   gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          #   caption: description
          # }
          # ... on ContentfulImage {
          #   id
          #   contentful_id
          #   caption
          #   altText
          #   image {
          #     title
          #     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          #     caption: description
          #   }
          # }
          # ... on ContentfulLayoutLeftRight {
          #   id
          #   contentful_id
          #   name
          #   ...LayoutLeftRight
          # }
          # ... on ContentfulVideo {
          #   contentful_id
          #   ...Video
          # }

          # ... on ContentfulLayoutGrid {
          #   contentful_id
          #   ...Grid
          # }
          # ... on ContentfulPosts {
          #   contentful_id
          #   slug
          #   date
          #   year: date(formatString: "YYYY")
          #   month: date(formatString: "MM")
          #   postType {
          #     name
          #   }
          #   category {
          #     name
          #   }
          # }
        }
      }
      description {
        description
      }
      image: highlightImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      imageDisplayOptions
      name
      structuredData {
        altText
        noIndex
        noFollow
        description {
          description
        }
        headline
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`
